export var SearchList = [
  { labe: '考评指标代码', code: 'name', type: 'input' },
  { labe: '考评指标描述', code: '', type: 'input' }

]
export var SearchData = {
  name: ''
}
export var menudata = [{
  label: '+新增',
  action: 'CreateaStatement',
  id: 1
}]
